import {
  CCFPhotoCategory,
  MiniForecastType,
  MiniWeatherType,
  PhotoCategory,
  Risk,
  WeatherRiskInput,
} from "./common_types";
import { DateTime } from "luxon";
import { ESTACIONES_COMUNIDAD_CONTRA_EL_FUEGO } from "./weather_stations";
import { FUNDO_ALHUE_GROUP_ID } from "./wa_users";

// P90_Santiago es 33.1 segun Informe Agrometeorológico Enero 2025.
export const TEMP_ALERTA_NARANJA = 33.1;
export const TEMP_ALERTA_ROJA = 35;
export const TEMP_ALERTA_MAGENTA = 38;
const END_PRIMAVERA_WEEK = 43;

const checkIsGoingToRain = (
  weather: MiniWeatherType | undefined,
  forecast: MiniForecastType | undefined,
) => {
  if (forecast && forecast.precipChance > 50) {
    return true;
  }
  if (weather && weather.dailyrainmm > 1) {
    return true;
  }
  return false;
};

/**
 * Esta función es invocada cuando no se ha detectado ningún riesgo activo, por lo cual usamos el calendario
 * para decidir que tipo de mensaje preventivo mostrar
 * @param weekNumber
 * @param dayOfWeek
 * @param hour
 * @param weather
 * @param forecast
 * @param chatId
 */
export function getImagenPreventivaCategoria(
  weekNumber: number,
  dayOfWeek: number,
  hour: number,
  weather: MiniWeatherType | undefined,
  forecast: MiniForecastType | undefined,
  chatId?: string,
) {
  const maxTemperature = Math.max(
    weather?.max_daily_tempc || 0,
    forecast?.temperatureMax || 0,
    weather?.tempc || 0,
  );

  const random = Math.random() * 10;

  console.log({ random, chatId });

  if (chatId === FUNDO_ALHUE_GROUP_ID && random < 1) {
    return "fundo alhue";
  }

  const isGoingToRain = checkIsGoingToRain(weather, forecast);

  if ((weekNumber >= 51 || weekNumber === 1) && hour >= 17) {
    return "fuegos artificiales";
  }

  if (
    weekNumber >= 33 &&
    maxTemperature >= 20 &&
    weekNumber <= END_PRIMAVERA_WEEK
  ) {
    if (maxTemperature >= 28) {
      return "temporada incendios";
    }

    return "primavera";
  }

  if (isGoingToRain) {
    return "lluvia";
  }

  if (
    weekNumber >= 13 &&
    weekNumber < 16 &&
    weather &&
    weather.tempc &&
    weather.tempc < 9 &&
    weather.tempc > 0
  ) {
    return "otoño invierno";
  }

  if (weekNumber >= 16 && weekNumber <= 34) {
    return "otoño invierno";
  }

  if (weekNumber >= 35 && weekNumber <= END_PRIMAVERA_WEEK) {
    return "primavera";
  }

  if (dayOfWeek === 6 || (dayOfWeek === 7 && hour <= 12)) {
    return "fin de semana";
  }

  return "temporada incendios";
}

const DEFAULT_CCF_IMAGES: CCFPhotoCategory = "CCF-prevención y mitigación";

const CCF_IMAGES_CATEGORY: { [key in PhotoCategory]: CCFPhotoCategory } = {
  "humo IF": DEFAULT_CCF_IMAGES,
  humo: DEFAULT_CCF_IMAGES,
  "Temperaturas altas": "CCF-altas temperaturas",
  "temporada incendios": DEFAULT_CCF_IMAGES,
  vientos: "CCF-viento",
  frío: "CCF-santuarios",
  primavera: DEFAULT_CCF_IMAGES,
  lluvia: "CCF-santuarios",
  "otoño invierno": "CCF-santuarios",
  "fin de semana": "CCF-fin de semana",
  "fuegos artificiales": "CCF-fin de año",
  calor: "CCF-altas temperaturas",
  "fundo alhue": DEFAULT_CCF_IMAGES,
};

export function getRiskIndex({
  weather,
  forecast,
  stationName,
  chatId,
}: {
  weather?: WeatherRiskInput;
  forecast?: MiniForecastType;
  stationName?: string;
  chatId?: string;
}): Risk {
  const station = weather?.station.name || stationName;
  if (station && ESTACIONES_COMUNIDAD_CONTRA_EL_FUEGO.includes(station)) {
    return getCCFRiskIndex(weather, forecast);
  } else {
    return getRiskIndexOriginal(weather, forecast, chatId);
  }
}

export function getCCFRiskIndex(
  weather?: WeatherRiskInput,
  forecast?: MiniForecastType,
) {
  const dayOfWeek = DateTime.now().setZone("America/Santiago").weekday;
  const risk = getRiskIndexOriginal(weather, forecast);

  if (risk.photoCategory === "temporada incendios") {
    if (dayOfWeek === 5) {
      risk.photoCategory = "CCF-santuarios";
      return risk;
    }
  }

  risk.photoCategory =
    CCF_IMAGES_CATEGORY[risk.photoCategory as PhotoCategory] ||
    "CCF-santuarios";
  return risk;
}

export function getRiskIndexOriginal(
  weather?: WeatherRiskInput,
  forecast?: MiniForecastType,
  chatId?: string,
): Risk {
  const dayOfWeek = DateTime.now().setZone("America/Santiago").weekday;
  const weekNumber = DateTime.now().setZone("America/Santiago").weekNumber;
  const hour = DateTime.now().setZone("America/Santiago").hour;

  // humo es humo invierno
  // humo IF es humo producto de un incendio forestal
  const humoCategory = weekNumber > 45 ? "humo IF" : "humo";

  if (!weather) {
    return {
      category: "norisk",
      color: "rgba(94,255,0,0.6)",
      photoCategory: getImagenPreventivaCategoria(
        weekNumber,
        dayOfWeek,
        hour,
        weather,
        forecast,
      ),
      emoji: "",
    };
  }

  const tempc = Math.round(weather.tempc);

  if (weather.PM2_5Density && weather.PM2_5Density >= 170) {
    return {
      category: "airQuality",
      color: "rgba(255,0,255,0.6)",
      photoCategory: humoCategory,
      message: "Emergencia Ambiental‼️",
      emoji: "🟣",
    };
  }
  if (tempc >= TEMP_ALERTA_MAGENTA) {
    return {
      category: "temperature",
      color: "rgba(255,0,255,0.6)",
      photoCategory: "calor",
      message:
        "Temperaturas extremas. Extremar medidas para prevenir incendios forestales‼️",
      emoji: "🟣",
    };
  }
  if (weather.PM2_5Density && weather.PM2_5Density >= 110) {
    return {
      category: "airQuality",
      color: "rgba(255,0,0,0.6)",
      message: "Pre emergencia Ambiental‼️",
      photoCategory: humoCategory,
      emoji: "🔴",
    };
  }
  if (tempc >= TEMP_ALERTA_ROJA) {
    return {
      category: "temperature",
      color: "rgba(255,0,0,0.6)",
      message:
        "Temperaturas extremas. Extremar medidas para prevenir incendios forestales‼️",
      photoCategory: "calor",
      emoji: "🔴",
    };
  }
  if (tempc >= 31 && weather.humidity <= 29 && weather.windgustkph >= 31) {
    return {
      category: "wildfire",
      color: "rgba(255,0,0,0.6)",
      message:
        "30-30-30. Extremar medidas para prevenir incendios forestales‼️",
      photoCategory: "temporada incendios",
      emoji: "🔴",
    };
  }
  if (Math.round(weather.windgustkph) >= 90) {
    return {
      category: "wind",
      color: "rgba(255,0,0,0.6)",
      photoCategory: "vientos",
      message: "Vientos Extremos‼️",
      emoji: "🔴",
    };
  }
  if (weather.PM2_5Density && weather.PM2_5Density >= 80) {
    return {
      category: "airQuality",
      color: "rgba(255,100,0,0.6)",
      photoCategory: humoCategory,
      message: "Alerta ambiental‼️",
      emoji: "🟠",
    };
  }
  if (tempc >= TEMP_ALERTA_NARANJA) {
    return {
      category: "temperature",
      color: "rgba(255,100,0,0.6)",
      photoCategory: "Temperaturas altas",
      message:
        "Altas temperaturas. Extremar medidas para prevenir incendios forestales‼️",
      emoji: "🟠",
    };
  }
  if (Math.round(weather.windgustkph) >= 60) {
    return {
      category: "wind",
      color: "rgba(255,100,0,0.6)",
      photoCategory: "vientos",
      message: "Vientos Extremos‼️",
      emoji: "🟠",
    };
  }
  if (weather.PM2_5Density && weather.PM2_5Density >= 36) {
    return {
      category: "airQuality",
      color: "rgba(238,255,0,0.6)",
      photoCategory: humoCategory,
      message: "Mala calidad del aire",
      emoji: "🟡",
    };
  }
  if (Math.round(weather.windgustkph) >= 30) {
    return {
      category: "wind",
      color: "rgba(238,255,0,0.6)",
      photoCategory: "vientos",
      message: "Fuertes Vientos‼️",
      emoji: "🟡",
    };
  }
  if (tempc >= 31) {
    return {
      category: "temperature",
      color: "rgba(238,255,0,0.6)",
      photoCategory: "Temperaturas altas",
      message:
        "Altas temperaturas. Extremar medidas para prevenir incendios forestales‼️",
      emoji: "🟡",
    };
  }

  if (tempc <= 3 || (weather.dailyrainmm > 0 && tempc <= 5)) {
    return {
      category: "temperature",
      color: "rgba(122,164,255,0.6)",
      photoCategory: "frío",
      message: "Bajas temperaturas‼️",
      emoji: "🔵",
    };
  }

  //no current risks
  return {
    category: "norisk",
    color: "rgba(94,255,0,0.6)",
    photoCategory: getImagenPreventivaCategoria(
      weekNumber,
      dayOfWeek,
      hour,
      weather,
      forecast,
      chatId,
    ),
    emoji: "",
  };
}
