import Mongoose from "mongoose";

export type SirenaStatusType = {
  connected: boolean;
  lastUpdate: Date;
  changesLast24Hours: number;
};

export type SirenaStatusReportType = {
  [key: string]: SirenaStatusType;
};

export type Coordinates = {
  latitude: number;
  longitude: number;
};

export type SirenaReport = {
  up: number;
  total: number;
  createdAt: Date;
  status: SirenaStatusReportType;
};

export type CommunityInviteType = {
  _id?: Mongoose.Types.ObjectId;
  invitationCode: string;
  // cuantas veces se ha usado
  invitationCodeUses: number;
  communityId: Mongoose.Types.ObjectId;
  createdAt: Date;
  active: boolean;
  maxUses: number;
};

export type ComunidadType = {
  _id: any;
  name: string;
  //este es el nombre de la comunidad en whatsapp
  nameGroup?: string;
  whatsappChatId?: string;
  alarmTest: boolean;
  alarming: boolean;
  alarmUntil: Date;
  alarmEventId: string;
  lastAlarmTriggeredBy: string;
  lastAlarmTriggeredAt: Date;
  lastSirenaReport?: SirenaReport;
  location?: {
    type: "Point";
    coordinates: number[];
  };
  geomap?: {
    type: "Polygon";
    coordinates: number[][][];
  };
  ownerId: string;
};

export type NativeMemberType = {
  id: string;
  name: string;
  address: string;
};

export type CommunityJoinRequestType = {
  _id: Mongoose.Types.ObjectId;
  communityId: Mongoose.Types.ObjectId;
  memberId: Mongoose.Types.ObjectId;
  memberName: string;
  memberAddress: string;
  createdAt: Date;
  status: string;
};

export type NativeCommunityType = {
  _id: any;
  name: string;
  //name with namespace, this is to prevent two communities with the same name owned by the same person
  fullName: string;
  ownerId: string;
  hasSirens: boolean;
  alarming: boolean;
  alarmUntil: Date;
  alarmEventId: string;
  lastAlarmTriggeredBy: string;
  lastAlarmTriggeredAt: Date;
  lastSirenaReport?: SirenaReport;
  location?: {
    type: "Point";
    coordinates: number[];
  };
  members: NativeMemberType[];
};

export type PropertyType = {
  _id?: Mongoose.Types.ObjectId;
  comunidad: string;
  name: string;
  parcela: number;
  etapa: number;
  calle?: string;
  letra?: string;
  latitude: number;
  longitude: number;
  sirena?: string;
  sirenaStatus?: string;
  sirenaLastUpdate?: Date;
  sirenaSourceIp?: string;
  version: number;
  location?: {
    type: "Point";
    coordinates: [number, number];
  };
  vehicles?: Mongoose.Types.ObjectId[];
};

export type MessageType = {
  _id?: string;
  title: string;
  content: string;
  target: string;
  comunidad: string;
  critical: boolean;
  createdAt: Date;
  alarmaTest?: boolean;
  alarmaTrigger?: string;
};

export type SirenaType = {
  _id?: Mongoose.Types.ObjectId;
  name: string;
  alias: string;
  createdAt: Date;
  updatedAt: Date;
  comunidad: string;
  location?: {
    type: "Point";
    coordinates: [number, number];
  };
  sirenaStatus?: string;
  sirenaLastUpdate?: Date;
  sirenaSourceIp?: string;
};

export type SirensTimeseriesType = {
  timestamp: Date;
  metadata: {
    clientId: string;
    connected: boolean;
  };
};

type VehiclePropertyLink = {
  property: Mongoose.Types.ObjectId;
  residente: boolean;
  visita: boolean;
};

export type VehicleType = {
  _id?: Mongoose.Types.ObjectId;
  properties: VehiclePropertyLink[];
  patente: string;
  tipo: string;
  marca: string;
  modelo: string;
  year: number;
  color: string;
};

export type VehicleDataType = {
  patente: string;
  tipo: string;
  marca: string;
  modelo: string;
  year?: number;
  color?: string;
};

export type UserType = {
  _id?: Mongoose.Types.ObjectId;
  property: Mongoose.Types.ObjectId;
  comunidad: string;
  phoneNumber: string;
  email: string;
  name: string;
  fullName: string;
  sortOrder?: number;
  permisos: Permiso[];
  invitationCode: string;
  invitationCodeUses: number;
  expoToken?: string;

  //@deprecated
  messages: MessageType[];
};

export type NativeUserType = {
  _id?: Mongoose.Types.ObjectId;
  userId: string;
  communities: Mongoose.Types.ObjectId[];
  phoneNumber?: string;
  email: string;
  name: string;
  fullName: string;
  permisos: Permiso[];
  type: string;
  address: string;
  plan: "free" | "paid";
};

export type HeartbeatType = {
  timestamp: Date;
  metadata: {
    source: string;
    clientId: string;
    wwebjsListening?: boolean;
  };
};

export type ExpoTokenType = {
  userId: Mongoose.Types.ObjectId;
  expoToken: string;
};

export type Permiso = "sirena" | "sirenaAlhue";

export type QuotaMode = "explicit" | "hidden";

export type QuotaType =
  | "unlimited"
  | {
      minutes: number;
      requests: number;
      mode: QuotaMode;
    };

export type TriggerAlarmIntend = IntendActionBase & {
  action: "triggerAlarm";
  user: UserType;
  community: string;
  property: PropertyType | null;
  payload: { on: boolean; test: boolean; message?: string };
};

export type TwitterMessage = {
  body: string;
  username: string;
  link: string;
  timestamp: string;
  photos?: string[];
  externalLink?: string;
};

export type RedButtonMessage = {
  comunas: string[];
  url: string;
};

export type WAIncomingMessage = {
  author: string;
  body: string;
  id: string;
  hasMedia: boolean;
  isReply: boolean;
  chatbotVersion: string;
  chatbotWorker: string;
  inReplyToMessageId?: string;
  group?: string;
  payload?: any;
};

export type WAOutputMessage = {
  /**
   * identificador único para un grupo de mensajes, este es el id que se utiliza posteriormente para recuperar estadísticas
   */
  campaignId: string;

  /**
   * tipo de campaña que se está enviando
   */
  campaignType: string;

  /**
   * mensaje a publicar
   */
  body: string | null;

  /**
   * url de una imagen a incluir en el mensaje
   */
  photo?: string;

  /**
   * texto de referencia para identificar a que corresponde la foto
   */
  sourcePhoto?: string;

  /**
   * lista de chats a los que se debe enviar el mensaje.
   */
  chatIds: string[];

  /**
   * indica si el mensaje se debe enviar con una pausa o de manera urgente
   */
  critical: boolean;
};

export type WAPublishMessage = {
  campaignId?: string;
  body: string;
  photo?: string;
  chatId: string;
  critical: boolean;
};

export type CampaignType = WAOutputMessage & {
  campaignId: string;
  timestamp: Date;
  campaignType: string;
  messageIds?: string[];
};

export type CampaignTask = CampaignType & {
  //chat al cual se debe enviar la respuesta
  chatId: string;
};

export type CampaignTrackMessage = {
  campaignId: string;
  messageId: string;
};

export enum WMessageEventType {
  READ = "read",
  DELIVERY = "delivery",
}

export type CampaignStatsType = {
  timestamp: Date;
  event: WMessageEventType;
  user: string;
  metadata: {
    campaignId: string;
    campaignType: string;
    chatId: string;
    messageId: string;
    sentAt: Date;
  };
};

export type ChatMembersNumberType = {
  timestamp: Date;
  members: number;
  metadata: {
    chatId: string;
  };
};

export type EstacionMeteorologica = {
  name: string;
  location: {
    type: string;
    coordinates: [number, number];
  };
  source: string;
  url?: string;
};

/**
 * Valores utilizados para calcular el índice de riesgo.
 */
export type WeatherRiskInput = {
  station: {
    name: string;
  };
  PM2_5Density?: number | null | undefined;

  tempc: number;
  humidity: number;
  windgustkph: number;
  dailyrainmm: number;

  uv?: number | null | undefined;
};

export type PhotoCategory =
  | "humo IF"
  | "humo"
  | "calor"
  | "temporada incendios"
  | "vientos"
  | "Temperaturas altas"
  | "frío"
  | "fundo alhue"
  | "fuegos artificiales"
  | "lluvia"
  | "otoño invierno"
  | "primavera"
  | "fin de semana";

export type CCFPhotoCategory =
  | "CCF-altas temperaturas"
  | "CCF-prevención y mitigación"
  | "CCF-viento"
  | "CCF-santuarios"
  | "CCF-fin de semana"
  | "CCF-fin de año";

export type Risk = {
  category: string;
  color: string;
  emoji: string;
  message?: string;
  photoCategory: PhotoCategory | CCFPhotoCategory;
};

export type MiniWeatherType = {
  tempc: number;
  dailyrainmm: number;
  max_daily_tempc?: number;
};

export type MiniForecastType = {
  temperatureMax: number;
  precipChance: number;
};

export type WeatherType = WeatherRiskInput & {
  station: EstacionMeteorologica;
  timestamp: Date;

  PM1_0Density?: number;
  PM10Density?: number;

  windspeedkph: number;
  winddir: number;

  //lluvia en el dia actual.
  dailyrainmm: number;

  max_daily_tempc: number;
  time_max_daily_tempc: Date;

  min_daily_tempc: number;
  time_min_daily_tempc: Date;

  max_daily_windgustkph: number;
  time_max_daily_windgustkph: Date;
  // Valores que no siempre estan pero si no esn no importa
  solarradiation?: number;

  //lluvia durante el último evento, puede incluir varios días
  eventrainmm?: number;

  //hourly rain es una tasa de la velocidad con que cae la lluvia en los ultimos minutos, no representa la lluvia en la ultima hora
  hourlyrainmm?: number;
  weeklyrainmm?: number;
  monthlyrainmm?: number;

  yearlyrainmm?: number;
};

export type WeatherTimeseriesType = WeatherType & {
  timestamp: Date;
  station: EstacionMeteorologica;
};

export type IntendActionCommon = {
  chatId: string;
  messageId: string;
  community?: string;
  permissions: Permiso[];
  user: UserType | null;
  quota: QuotaType;
};

export type ActionType =
  | "ping"
  | "triggerAlarm"
  | "chat"
  | "alarmaStatus"
  | "rebootAlarma"
  | "testSirena"
  | "requestWeather"
  | "requestForecast"
  | "requestStats"
  | "sendChat"
  | "requestVehicleInfo"
  | "requestEmergencyNumber"
  | "deliverVehicleInfo"
  | "requestContacts"
  | "addHashtag"
  | "requestRules";

export type IntendActionBase = IntendActionCommon & {
  action: ActionType;
};

export type IntendAction = IntendActionBase &
  (
    | TriggerAlarmIntend
    | { action: "ping"; payload: { chatbotVersion: string } }
    | { action: "chat"; payload: { message: string } }
    | { action: "sendChat"; payload: { message: string } }
    | { action: "alarmaStatus" }
    | { action: "requestWeather"; payload: { station: string } }
    | { action: "requestForecast"; payload: { station: string } }
    | { action: "requestStats"; payload: { messageId: string } }
    | { action: "rebootAlarma"; payload: { sirenaId: string } }
    | { action: "testSirena"; payload: { sirenaId: string; duration: number } }
    | { action: "requestVehicleInfo"; payload: { plate: string } }
    | { action: "requestEmergencyNumber"; payload: { trigger: string } }
    | { action: "requestContacts"; payload: { csvFilePath: string } }
    | { action: "addHashtag"; payload: { hashtag: string } }
    | { action: "requestRules" }
  );

export type BenderAction =
  | {
      action: "requestVehicleInfo";
      chatId: string;
      plate: string;
    }
  | { action: "fetchTweet"; username: string; link: string }
  | {
      action: "requestRedButton";
      dateString: string;
    };

export type GoogleDriveImage = {
  fullPath: string;
  webContentLink: string;
};

export type BomberosTelegramInput = {
  source:
    | "curacavi"
    | "paine"
    | "Bomberos_Tiltil"
    | "CBMelipilla1910"
    | "tiltil";
  message: string;
  provider?: "viper" | "telegram";
  location?: Coordinates;
};

export type BomberosTwitterType = {
  body: string;
  dispatchTime: string;
};

export type SummaryMembersType = {
  timestamp: Date;
  members: number;
  metadata: {
    project: string;
    groups: number;
  };
};
